<template>
  <v-form>
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row no-gutters >
      <v-col cols="2">
        <DateTimePicker
          v-model="openedAt"
          :rules="[presenceRule]"
          :readonly="readonly"
          dense
          filled
          label="Data spesa"
          hide-details="auto"
          @input="updateField('openedAt', $event)"
        ></DateTimePicker>
      </v-col> 
      <v-col cols="5" class="pl-1">
        <NumericKeyboardInput
          v-model="amount"
          :readonly="readonly"
          filled
          :dense="false"
          label="Ammontare"
          hide-details="auto"
          @input="updateField('amount', $event)"
        ></NumericKeyboardInput>
      </v-col>
      <v-col cols="5" class="pl-2">
        <OperatorsAutocomplete
          v-model="operator"
          :readonly="readonly"
          return-object
          clearable
          :multiple="false"
          label="Operatore"
          @input="updateField('operator', $event)"
        ></OperatorsAutocomplete>
      </v-col> 
    </v-row>
    <v-row no-gutters class="py-1">
      <v-col class="mt-5" cols="7">
        <v-textarea
          v-model="reason"
          :readonly="readonly"
          label="Motivazione"
          filled
          hide-details="auto"
          @input="updateField('reason', $event)"
        ></v-textarea>
      </v-col>
      <v-col class="mt-5 ml-5" cols="4">
        <TagsAutocomplete
          multiple
          :readonly="readonly"
          v-model="tags"
          dense
          return-object
          @input="updateField('tags', $event)"
        ></TagsAutocomplete>
      </v-col>
      <v-col>
        <v-switch 
          v-model="ghost"
          :readonly="readonly"
          class="ml-8"
          color="black"
          hide-details
          @change="handleGhostChange"
        ></v-switch>
      </v-col> 
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import paymentDebitForm from '@/services/paymentDebits/paymentDebit.form.js'
import NumericKeyboardInput from '@/components/common/NumericKeyboardInput.vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import TagsAutocomplete from '@/components/common/TagsAutocomplete.vue'
import OperatorsAutocomplete from '@/components/common/OperatorsAutocomplete.vue'

export default {
  name: "GeneralFormTab",
  components: {
    DateTimePicker,
    NumericKeyboardInput,
    OperatorsAutocomplete,
    TagsAutocomplete,
  },
  data: function() {
    return {
      openedAt: undefined,
      amount: undefined,
      customer: undefined,
      operator: undefined,
      reason: undefined,
      loadingPaymentDebit: false,
      type: undefined,
      documentType: undefined,
      tags: undefined,
      ghost: undefined,
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    readonly: {
      type: Boolean,
      default: false,
    }
  },
  mounted: function() {
    this.handleObjectChanges(paymentDebitForm.paymentDebit)

    var self = this
    paymentDebitForm.on('update', function(data) {
      self.handleObjectChanges(data.paymentDebit)
    })
  },
  methods: {
    handleObjectChanges(paymentDebit) {
      if(!!paymentDebit) {
        if(!!paymentDebit.openedAt) {
          this.openedAt = new Date(paymentDebit.openedAt)
        }

        if(!!paymentDebit.amount) {
          this.amount = Number(paymentDebit.amount)
        }

        if(!!paymentDebit.type) {
          this.type = paymentDebit.type

          if(paymentDebit.type == 'customer') {
            this.customer = !!paymentDebit.customer ? paymentDebit.customer : undefined
          } else if(paymentDebit.type == 'bill') {
            this.customer = !!paymentDebit.bills && paymentDebit.bills.length > 0 && !!paymentDebit.bills[0].billCustomers && paymentDebit.bills[0].billCustomers.length > 0 && !!paymentDebit.bills[0].billCustomers[0].customer ? 
              paymentDebit.bills[0].billCustomers[0].customer : undefined
          } else if(paymentDebit.type == 'operator') {
            this.operator = !!paymentDebit.operator ? paymentDebit.operator : undefined
          }
        }

        if(!!paymentDebit.reason) {
          this.reason = paymentDebit.reason
        }

        if(!!paymentDebit.documentType) {
          this.documentType = paymentDebit.documentType
        }

        if(!!paymentDebit.tags) {
          this.tags = paymentDebit.tags
        }

        if(!!paymentDebit.ghost) {
          this.ghost = paymentDebit.ghost
        }
      }
    },
    updateField(key, value) {
      paymentDebitForm.updateField(key, value)
      if(key == 'amount' && !!this.ghost)
        paymentDebitForm.updateField('ghostPaid', value)
      else if(key == 'amount' && !this.ghost)
        paymentDebitForm.updateField('cashPaid', value)
      
    },
    handleGhostChange(){
      if(!!this.ghost){ 
        this.updateField('ghostPaid',this.amount)
        this.updateField('cashPaid',0)
      } else {
        this.updateField('cashPaid', this.amount)
        this.updateField('ghostPaid', 0)
      }
  
    }
  },
  watch: {
    openedAt() {
      const newVal = (!!this.openedAt && !!this.amount && !!this.operator )
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    amount() {
      const newVal = (!!this.openedAt && !!this.amount && !!this.operator )
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
    operator() {
      const newVal = (!!this.openedAt && !!this.amount && !!this.operator )
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      paymentDebitForm.setValid(newVal)
    },
  }
};
</script>